/**
 * Timetastic
 * Stars component
 */
import React from "react"
import PropTypes from "prop-types"

// Styles
import "./stars.scss"

const Stars = ({ namespace, numberOfStars, size }) => {
  let stars = []
  const hasHalfStar = numberOfStars % 1 !== 0
  const roundedDownStars = Math.floor(numberOfStars)

  for (let i = 0; i < roundedDownStars; i++) {
    stars.push(
      <svg
        aria-hidden="true"
        className="c-star"
        focussable="false"
        height={size}
        key={`star-${namespace}-${i}`}
        viewBox="0 0 13 13"
        width={size}
        xmlns="http://www.w3.org/2000/svg"
      >
        >
        <use xlinkHref="#svg-star" />
      </svg>
    )
  }

  if (hasHalfStar) {
    stars.push(
      <svg
        className="c-star c-star--half"
        height={size}
        key={`star-${namespace}-half`}
        viewBox="0 0 13 13"
        width={size}
        xmlns="http://www.w3.org/2000/svg"
      >
        <use xlinkHref="#svg-star-half" />
      </svg>
    )
  }

  return stars.map(star => star)
}

Stars.propTypes = {
  namespace: PropTypes.string,
  numberOfStars: PropTypes.number.isRequired,
  size: PropTypes.number,
}

Stars.defaultProps = {
  namespace: "",
  size: 12,
}

export default Stars
