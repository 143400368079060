import React, { Children, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from 'gatsby-plugin-image'
import { BgImage, convertToBgImage } from 'gbimage-bridge'

//Background images:
const BackgroundBlobs = ({ children, blobType }) => {

  //Placeholder docs: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-plugin-image/#placeholder
  //Changing the props here will trigger a reload but the image won't update until you edit the "container" 

  // GraphQL Looks for images in:  \src\images 
  // This is the mapping between the files themselves and where Gatsby pushes them through the image mafipulator
  // We use [image]-portrait.jpg for portrait images and
  // [image]-landscape.jpg for landscape.
  // You can modify individual image properties in the graphQL query and it'll affect JUST that image reference.
  // If we can't find the image in the query we'll fall back to "hero blobs"

  /* So to insert a background image named dave you would:
  1) Create the dave-portrait.jpg and dave-landscape.jpg in \src\images
  2) Add the Images to the GraphQL query so Gatsby can find them
  2b) Make sure the names you want to refer to them are references in the staticQuery (the const line under this comment)
  3) Add the bits to the switch statement to reference the "string" type we pass in to the object. (There's probably a way to do this directly but I don't want to use eval and this is easy to understand)
  4) Add the following markup to the page:
      <BackgroundBlobs blobType='dave'>
      [more markup for the content]
      </BackgroundBlobs> 
  */

  const { heroBlobs_portrait, heroBlobs_landscape, calendarBlobs_portrait, calendarBlobs_landscape, howHeroBlobs_landscape, howCalendarBlobs_landscape, howCalendarBlobs_portrait, holidayCalcBlobs, longlistBlobs, longlistBlobs_landscape } = useStaticQuery(
    graphql`
      query {
          heroBlobs_landscape: file(relativePath: {eq: "hero-blobs_landscape.jpg"}) {
            childImageSharp {
              gatsbyImageData(
                width: 1295,
                quality: 70
              ) 
            }
          }
          heroBlobs_portrait: file(relativePath: {eq: "hero-blobs_portrait.jpg"}) {
            childImageSharp {
              gatsbyImageData(
                width: 494,
                quality: 70
              )
            }
          }
          calendarBlobs_landscape: file(relativePath: {eq: "calendar-blobs_landscape.jpg"}) {
            childImageSharp {
              gatsbyImageData(
                width: 1280,
                quality: 70
              ) 
            }
          }
          calendarBlobs_portrait: file(relativePath: {eq: "calendar-blobs_portrait.jpg"}) {
            childImageSharp {
              gatsbyImageData(
                width: 485,
                quality: 70
              )
            }
          }
          howHeroBlobs_landscape: file(relativePath: {eq: "how-hero-blobs_landscape.jpg"}) {
            childImageSharp {
              gatsbyImageData(
                width: 910,
                quality: 70
              ) 
            }
          }
          howCalendarBlobs_landscape: file(relativePath: {eq: "how-calendar-blobs_landscape.jpg"}) {
            childImageSharp {
              gatsbyImageData(
                width: 1003,
                quality: 70
              ) 
            }
          }
          howCalendarBlobs_portrait: file(relativePath: {eq: "how-calendar-blobs_portrait.jpg"}) {
            childImageSharp {
              gatsbyImageData(
                width: 687,
                quality: 70
              )
            }
          }
          holidayCalcBlobs: file(relativePath: {eq: "holiday-calculator-blobs.jpg"}) {
            childImageSharp {
              gatsbyImageData(
                width: 800,
                quality: 70
              )
            }
          }
          longlistBlobs: file(relativePath: {eq: "longlist-blobs_portrait.jpg"}) {
            childImageSharp {
              gatsbyImageData(
                width: 465,
                quality: 70
              )
            }
          }
          longlistBlobs_landscape: file(relativePath: {eq: "longlist-blobs_landscape.jpg"}) {
            childImageSharp {
              gatsbyImageData(
                width: 1295,
                quality: 70
              )
            }
          }
      }
      `
  )

  let sources = [
    getImage(heroBlobs_portrait),
    {
      ...getImage(heroBlobs_landscape),
      media: `(min-width: 420px)`
    }
  ];


  if (typeof blobType != 'undefined') {

    switch (blobType) {
      case "howHeroBlobs":
        sources = [
          getImage(howHeroBlobs_landscape),
          {
            ...getImage(howHeroBlobs_landscape),
            media: `(min-width: 420px)`
          }
        ];
        break;
      case "howCalendarBlobs":
        sources = [
          getImage(howCalendarBlobs_portrait),
          {
            ...getImage(howCalendarBlobs_landscape),
            media: `(min-width: 595px) and (max-width: 1023px)`
          }
        ];
        break;
      case "calendarBlobs":
        sources = [
          getImage(calendarBlobs_portrait),
          {
            ...getImage(calendarBlobs_landscape),
            media: `(min-width: 420px)`
          }
        ];
        break;
      default:
        sources = [
          getImage(heroBlobs_portrait),
          {
            ...getImage(heroBlobs_landscape),
            media: `(min-width: 420px)`
          }
        ];
        break;
      case "holidayCalcBlobs":
        sources = [
          getImage(holidayCalcBlobs),
          {
            ...getImage(holidayCalcBlobs),
            media: `(min-width: 420px)`
          }
        ];
        break;
      case "longlistBlobs":
        sources = [
          getImage(longlistBlobs),
          {
            ...getImage(longlistBlobs_landscape),
            media: `(max-width: 1023px)`
          }
        ];
        break;
    }
  }

  return (

    <BgImage image={sources}
      style={{
        backgroundSize: 'auto 100%',
        backgroundPosition: 'center top',
        backgroundRepeat: 'no-repeat',
      }}
    >
      {children}
    </BgImage>
  );
};

export default BackgroundBlobs